// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/calendar-block-renderer.css';
/**
 * @module squarespace-calendar-block-renderer
 */
YUI.add('squarespace-calendar-block-renderer', function(Y) {

  var calendarBlockInstances = [];

  Y.namespace('Squarespace');

  Y.Squarespace.CalendarBlockRenderer = {};

  Y.Squarespace.CalendarBlockRenderer.renderCalendar = function renderCalendar(contentEl, config, pageCollectionId) {
    var collectionId;

    if (Y.Lang.isString(config)) {
      collectionId = config;
    } else if (Y.Object.hasKey(config, 'collectionId')) {
      collectionId = config.collectionId;
      // It's a Y.Squarespace.Models.ContentCollection, but don't want to
      // bring in entire dependency tree just for the instance of check, in deference to
      // https://code.squarespace.net/projects/V6/repos/squarespace-v6/commits/b51dbddfb7e743ea16a32d1bd64f4f82482e9854
    } else if (Y.instanceOf(config, Y.Model)) {
      collectionId = config.get('id');
    }

    var params = {
      date: new Date(),
      collectionId: collectionId
    };

    if (Y.Lang.isValue(pageCollectionId)) {
      Y.merge(params, {
        pageCollectionId: pageCollectionId
      });
    }

    var calendar = new Y.Squarespace.SquarespaceCalendar(params);
    calendar.plug(Y.Squarespace.SquarespaceCalendarRenderer);
    calendar.render(contentEl);
    return calendar;
  };

  Y.Squarespace.CalendarBlockRenderer.initializeNode = function initializeNode(rootEl) {
    var blockContent = rootEl.one('.sqs-block-content');
    var blockJSON = Y.JSON.parse(rootEl.getAttribute('data-block-json'));

    // Has this block already been inserted into the DOM
    var isBlockInitialized = !!Y.Array.find(calendarBlockInstances, function (calendarBlockInstance) {
      return calendarBlockInstance.get('boundingBox').ancestor('.sqs-block-content') === blockContent;
    });
    if (isBlockInitialized) {
      return;
    }

    calendarBlockInstances.push(
      Y.Squarespace.CalendarBlockRenderer.renderCalendar(blockContent, blockJSON)
    );
  };

  Y.config.win.Squarespace.onInitialize(Y, function onInitialize() {
    var calBlocks = Y.all('.sqs-block.calendar-block[data-block-json]');
    if (calBlocks.size()) {
      calBlocks.each(Y.Squarespace.CalendarBlockRenderer.initializeNode);
    }
  });

  Y.config.win.Squarespace.onDestroy(Y, function onDestroy() {
    calendarBlockInstances.forEach(function(calendarInstance) {
      calendarInstance.destroy();
    });
    calendarBlockInstances.length = 0;
  });

}, '1.0', {
  requires: [
    'model',
    'squarespace-calendar-core-renderer'
  ]
});
