import * as DateTimeConstants from 'shared/constants/DateTime';

/**
 * This file is a copy of yui3/3.17.2/datatype-date-format/lang/datatype-date-format_en.js
 * Changes here being the strings are wrapped in translation helpers for extraction.
 *
 *  Solely used in ./calendar-block-renderer.js
 */

import { t } from 'shared/i18n';

YUI.add('squarespace-datatype-date-format', function (Y) {
  Y.Intl.add('datatype-date-format', 'en', {
    a: DateTimeConstants.WEEKDAYS_SHORT,
    A: [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")],





























    b: [t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec")],

















































    B: [t("January"), t("February"), t("March"), t("April"), t("May"), t("June"), t("July"), t("August"), t("September"), t("October"), t("November"), t("December")],

















































    c: '%a, %b %d, %Y %l:%M:%S %p %Z',
    p: ['AM', 'PM'],
    P: ['am', 'pm'],
    x: '%m/%d/%y',
    X: '%l:%M:%S %p' });

}, '1');