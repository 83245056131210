/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/calendar-block-renderer.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model/model.js';
import 'src/main/webapp/universal/scripts-v6/calendar-core-renderer.js';
import 'src/main/webapp/universal/scripts-v6/calendar-base-lang.js';
import 'src/main/webapp/universal/scripts-v6/datatype-date-format.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/calendar/calendar.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/calendar-base/calendar-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-parse/datatype-date-parse.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-format/datatype-date-format.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/datatype-date-math/datatype-date-math.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/calendarnavigator/calendarnavigator.js';
import 'src/main/webapp/templates-v6/handlebars/calendar-day.html';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';