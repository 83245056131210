import { t } from 'shared/i18n';

/**
 * Do not localize, this sadly may be used for option values or API requests
 *
 * @type {String[]}
 */
export const EN_MONTHS = [
'January',
'February',
'March',
'April',
'May',
'June',
'July',
'August',
'September',
'October',
'November',
'December'];


export const WEEKDAYS_SHORT = [t("Su"), t("Mo"), t("Tu"), t("We"), t("Th"), t("Fr"), t("Sa")];