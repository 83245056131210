/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-calendar-day-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["calendar-day.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, stack2, options, self=this, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n	";
  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[1])),stack1 == null || stack1 === false ? stack1 : stack1.title), {hash:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "";
  return buffer;
  }

function program4(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n	<div class=\"background";
  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.structuredContent)),stack1 == null || stack1 === false ? stack1 : stack1.startDate), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\">\n    <a href=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.fullUrl)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\" class=\"background-image-link\">\n      <img\n        alt=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.title)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"\n        data-src=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.assetUrl)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"\n        data-image-dimensions=\""
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.originalSize)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"\n        data-load=\"false\"\n        class=\"background-image\"\n      >\n    </a>\n	</div>\n	";
  return buffer;
  }
function program5(depth0,data) {
  
  
  return " background--iseventscollection";
  }

function program7(depth0,data) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n<ul class=\"itemlist";
  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.structuredContent)),stack1 == null || stack1 === false ? stack1 : stack1.startDate), {hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\">\n	";
  options = {hash:{},inverse:self.noop,fn:self.program(10, program10, data),data:data};
  if (stack2 = helpers.events) { stack2 = stack2.call(depth0, options); }
  else { stack2 = depth0.events; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  if (!helpers.events) { stack2 = blockHelperMissing.call(depth0, stack2, options); }
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n</ul>\n\n<ul class=\"\n	flyoutitemlist\n	";
  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.structuredContent)),stack1 == null || stack1 === false ? stack1 : stack1.startDate), {hash:{},inverse:self.noop,fn:self.program(17, program17, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n	";
  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[1])),stack1 == null || stack1 === false ? stack1 : stack1.title), {hash:{},inverse:self.program(21, program21, data),fn:self.program(19, program19, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n\">\n	";
  options = {hash:{},inverse:self.noop,fn:self.program(28, program28, data),data:data};
  if (stack2 = helpers.events) { stack2 = stack2.call(depth0, options); }
  else { stack2 = depth0.events; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  if (!helpers.events) { stack2 = blockHelperMissing.call(depth0, stack2, options); }
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n</ul>\n\n<div class=\"itemcount\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1.length)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n";
  return buffer;
  }
function program8(depth0,data) {
  
  
  return " itemlist--iseventscollection";
  }

function program10(depth0,data) {
  
  var buffer = "", stack1, stack2, options;
  buffer += "\n	<li class=\"item";
  stack1 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, depth0.isSubsequentDayOfMultidayEvent, {hash:{},inverse:self.noop,fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n		<a href=\"";
  if (stack1 = helpers.fullUrl) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.fullUrl; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" class=\"item-link\">\n			<span class=\"item-time item-time--12hr\">";
  options = {hash:{},data:data};
  buffer += escapeExpression(((stack1 = helpers['calendar-compact-time-format'] || depth0['calendar-compact-time-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "calendar-compact-time-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + "&nbsp;</span>\n			<span class=\"item-time item-time--24hr\">";
  options = {hash:{
    'format': ("H:mm")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + "&nbsp;</span>\n			<span class=\"item-title\">";
  if (stack2 = helpers.title) { stack2 = stack2.call(depth0, {hash:{},data:data}); }
  else { stack2 = depth0.title; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "</span>\n			";
  stack2 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.noop,fn:self.program(15, program15, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n		</a>\n	</li>\n	";
  return buffer;
  }
function program11(depth0,data) {
  
  
  return " item--multiday";
  }

function program13(depth0,data) {
  
  
  return " item--ongoing";
  }

function program15(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "<span class=\"item-enddate\">&nbsp;";
  options = {hash:{
    'format': ("ll")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "</span>";
  return buffer;
  }

function program17(depth0,data) {
  
  
  return " flyoutitemlist--iseventscollection";
  }

function program19(depth0,data) {
  
  
  return "\n		flyoutitemlist--hasmorecontent\n	";
  }

function program21(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "\n		";
  options = {hash:{},inverse:self.noop,fn:self.program(22, program22, data),data:data};
  if (stack1 = helpers.events) { stack1 = stack1.call(depth0, options); }
  else { stack1 = depth0.events; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if (!helpers.events) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n	";
  return buffer;
  }
function program22(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n			";
  stack1 = helpers['if'].call(depth0, depth0.excerpt, {hash:{},inverse:self.program(25, program25, data),fn:self.program(23, program23, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n		";
  return buffer;
  }
function program23(depth0,data) {
  
  
  return "\n				flyoutitemlist--hasmorecontent\n			";
  }

function program25(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n				";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressLine1), {hash:{},inverse:self.noop,fn:self.program(26, program26, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n			";
  return buffer;
  }
function program26(depth0,data) {
  
  
  return "\n					flyoutitemlist--hasmorecontent\n				";
  }

function program28(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n	<li class=\"flyoutitem";
  stack1 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.noop,fn:self.program(29, program29, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, depth0.isSubsequentDayOfMultidayEvent, {hash:{},inverse:self.noop,fn:self.program(31, program31, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n		<h1 class=\"flyoutitem-title\"><a href=\"";
  if (stack1 = helpers.fullUrl) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.fullUrl; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" class=\"flyoutitem-link\">";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.noop,fn:self.program(33, program33, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "&nbsp;<span class=\"flyoutitem-link-arrow\"></span></a></h1>\n		<div class=\"flyoutitem-datetime flyoutitem-datetime--12hr\">\n			";
  stack1 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.program(37, program37, data),fn:self.program(35, program35, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n		</div>\n		<div class=\"flyoutitem-datetime flyoutitem-datetime--24hr\">\n			";
  stack1 = helpers['if'].call(depth0, depth0.isMultiday, {hash:{},inverse:self.program(41, program41, data),fn:self.program(39, program39, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n		</div>\n		<div class=\"flyoutitem-location\">\n			";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressTitle), {hash:{},inverse:self.noop,fn:self.program(43, program43, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n			";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressLine1), {hash:{},inverse:self.noop,fn:self.program(45, program45, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n			";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressLine2), {hash:{},inverse:self.noop,fn:self.program(47, program47, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n			";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressCountry), {hash:{},inverse:self.noop,fn:self.program(49, program49, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n		</div>\n		";
  stack2 = helpers['if'].call(depth0, depth0.excerpt, {hash:{},inverse:self.noop,fn:self.program(51, program51, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n	</li>\n	";
  return buffer;
  }
function program29(depth0,data) {
  
  
  return " flyoutitem--multiday";
  }

function program31(depth0,data) {
  
  
  return " flyoutitem--ongoing";
  }

function program33(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += " <span class=\"flyoutitem-enddate\">";
  options = {hash:{
    'format': ("ll")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "</span>";
  return buffer;
  }

function program35(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "\n				";
  options = {hash:{
    'format': ("ddd, MMM D, h:mma")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + " &ndash; ";
  options = {hash:{
    'format': ("ddd, MMM D, h:mma")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "\n			";
  return buffer;
  }

function program37(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "\n				";
  options = {hash:{
    'format': ("LT")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + " &ndash; ";
  options = {hash:{
    'format': ("LT")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "\n			";
  return buffer;
  }

function program39(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "\n				";
  options = {hash:{
    'format': ("ddd, MMM D, H:mm")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + " &ndash; ";
  options = {hash:{
    'format': ("ddd, MMM D, H:mm")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "\n			";
  return buffer;
  }

function program41(depth0,data) {
  
  var buffer = "", stack1, options;
  buffer += "\n				";
  options = {hash:{
    'format': ("H:mm")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.startDate), options)))
    + " &ndash; ";
  options = {hash:{
    'format': ("H:mm")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['date-format'] || depth0['date-format']),stack1 ? stack1.call(depth0, ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options) : helperMissing.call(depth0, "date-format", ((stack1 = depth0.structuredContent),stack1 == null || stack1 === false ? stack1 : stack1.endDate), options)))
    + "\n			";
  return buffer;
  }

function program43(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "<div class=\"flyoutitem-location-addresstitle\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressTitle)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>";
  return buffer;
  }

function program45(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "<div class=\"flyoutitem-location-address1\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressLine1)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>";
  return buffer;
  }

function program47(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "<div class=\"flyoutitem-location-address2\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressLine2)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>";
  return buffer;
  }

function program49(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "<div class=\"flyoutitem-location-addresscountry\">"
    + escapeExpression(((stack1 = ((stack1 = depth0.location),stack1 == null || stack1 === false ? stack1 : stack1.addressCountry)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>";
  return buffer;
  }

function program51(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "<div class=\"flyoutitem-excerpt\">";
  if (stack1 = helpers.excerpt) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.excerpt; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>";
  return buffer;
  }

  stack2 = helpers['if'].call(depth0, ((stack1 = ((stack1 = depth0.events),stack1 == null || stack1 === false ? stack1 : stack1[0])),stack1 == null || stack1 === false ? stack1 : stack1.systemDataId), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n\n<div class=\"marker\">\n	<div class=\"marker-dayname\">";
  options = {hash:{
    'format': ("ddd")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['day-of-month-format'] || depth0['day-of-month-format']),stack1 ? stack1.call(depth0, depth0.date, options) : helperMissing.call(depth0, "day-of-month-format", depth0.date, options)))
    + "</div>\n	<div class=\"marker-daynum\">";
  options = {hash:{
    'format': ("D")
  },data:data};
  buffer += escapeExpression(((stack1 = helpers['day-of-month-format'] || depth0['day-of-month-format']),stack1 ? stack1.call(depth0, depth0.date, options) : helperMissing.call(depth0, "day-of-month-format", depth0.date, options)))
    + "</div>\n</div>\n\n";
  stack2 = helpers['if'].call(depth0, depth0.events, {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n";
  return buffer;
  });})()

  var filename = 'calendar-day.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
