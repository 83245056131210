import * as DateTimeConstants from 'shared/constants/DateTime';

/**
 * This file is a copy of yui3/3.17.2/calendar-base/lang/calendar-base.js
 * Changes here being the strings are wrapped in translation helpers for extraction.
 *
 *  Solely used in ./calendar-block-renderer.js
 */
YUI.add('squarespace-calendar-base-lang', function (Y) {
  Y.Intl.add('calendar-base', 'en', {
    'very_short_weekdays': DateTimeConstants.WEEKDAYS_SHORT,
    'first_weekday': 0,
    weekends: [0, 6]
  });
}, '1');
