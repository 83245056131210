import moment from 'moment';
import { formatDateTime } from 'shared/i18n';
import dateFormat from 'shared/utils/dateFormat';
import shiftForWebsiteTimezoneDisplay from 'shared/utils/shiftForWebsiteTimezoneDisplay';
import RecordType from '@sqs/enums/RecordType';

/**
 * @module squarespace-calendar-core-renderer
 */
YUI.add('squarespace-calendar-core-renderer', function(Y) {

  Y.namespace('Squarespace');

  var API_DATE_FORMAT = 'MM-YYYY';
  var MONTH_YEAR_FORMAT = 'MMMM YYYY';

  var getEventTime = function(event) {
    return (event.recordType === RecordType.EVENT) ? event.structuredContent.startDate : event.addedOn;
  };

  var GET_ITEMS_BY_MONTH_PATH = '/api/open/GetItemsByMonth';

  /**
   * Note: this class by itself does not render any posts/events in the UI
   *
   * A base Squarespace Calendar class that extend the YUI Calendar
   * Adds functionality to fetch the posts in the current month, hooks
   * into the month navigation to next and previous are also being
   * fetched when clicked.
   *
   * @class       SquarespaceCalendar
   * @extends     Calendar
   * @constructor
   */
  Y.Squarespace.SquarespaceCalendar =
  Y.Base.create('SquarespaceCalendar', Y.Calendar, [], {
    initializer: function() {
      this._fetchEvents();
    },

    bindUI: function() {
      Y.Calendar.superclass.bindUI.call(this);
      this.before('dateChange', this._clearEvents, this);
      this.after('dateChange', this._fetchEvents, this);
      this.after('collectionIdChange', this._fetchEvents, this);
    },

    _clearEvents: function() {
      this.set('events', []);
    },

    _fetchEvents: function() {
      var currentDate = this.get('date');
      var currentDateFormatted = moment(currentDate)
        .locale('en')
        .format(API_DATE_FORMAT);
      Y.Data.get({
        url: this._getFetchUrl(currentDateFormatted),
        success: this._parseResponse,
        failure: this._handleResponseError
      }, this);
    },

    _parseResponse: function(data) {
      this.set('events', data);
    },

    _handleResponseError: function(data) {
      this.fire('fetchEventsFailure');
      if (__DEV__) {
        console.error('Failed fetch data: ' + data);
      }
    },

    _getFetchUrl: function(date) {
      return GET_ITEMS_BY_MONTH_PATH + this._buildQueryString(date);
    },

    _buildQueryString: function(date) {
      var collectionId = this.get('collectionId');
      var pageCollectionId = this.get('pageCollectionId');
      var category = this.get('category');
      var tag = this.get('tag');
      var author = this.get('author');

      var baseQueryString = '?month=' + date;

      if (collectionId) {
        baseQueryString += '&collectionId=' + collectionId;
      }

      if (pageCollectionId) {
        baseQueryString += '&pageCollectionId=' + pageCollectionId;
      }

      var queryString =
        baseQueryString +
        (category ? '&category=' + category : '') +
        (tag ? '&tag=' + tag : '') +
        (author ? '&author=' + author : '');

      return queryString;
    }
  },
  {
    ATTRS: {
      /**
       * @attribute events
       * @type      {Array}
       */
      events: {
        value: [],
        validator: Y.Lang.isArray
      },

      /**
       * @attribute collectionId
       * @type      {String}
       */
      collectionId: {
        value: null,
        validator: Y.Lang.isString
      },

      /**
       * @attribute pageCollectionId
       * @type      {String}
       */
      pageCollectionId: {
        value: null,
        validator: Y.Lang.isString
      },

      category: {
        value: '',
        validator: Y.Lang.isString
      },

      tag: {
        value: '',
        validator: Y.Lang.isString
      },

      author: {
        value: '',
        validator: Y.Lang.isString
      }
    }
  });

  /**
   * Plugin to SquarespaceCalendar
   *
   * Contains some basic methods to filter out what posts and events should be
   * displayed on what day in the calendar UI. Contains a very primitive UI on days
   * where posts/events are present
   *
   * This class should be exteded to create a more custom UI
   *
   * @class       CalendarBaseRenderer
   * @extends     Plugin.Base
   * @namespace   Squarespace
   * @constructor
   */
  Y.Squarespace.CalendarBaseRenderer = Y.Base.create(
    'CalendarBaseRenderer',
    Y.Plugin.Base,
    [],
    {
      initializer: function(config) {
        this.host = config.host;
        this.afterHostEvent('eventsChange', this._onEventsChange, this);
      },

      _onEventsChange: function(e) {
        var filterFunction = null;
        if (Y.Lang.isArray(e.newVal) && e.newVal.length > 0) {
          filterFunction = Y.bind(this._calendarFilterFunction, this);
        }
        /**
         * YUI Calendar way of render calendar
         * The filter function will be called on each day and will
         * have to figure out what events to show and modify the day
         * node to display them
         * "customRenderer" is being set here because it's one of the few ways
         * to force a calendar redraw, syncUI does not work.
         */
        this.host.set('customRenderer', {
          rules: { all: 'events' },
          filterFunction: filterFunction
        });
      },

      _calendarFilterFunction: function(date, node, rules) {
        var events = this.host.get('events');
        var dayEvents = this._getEventsOfDay(events, date);
        this._sortEvents(dayEvents);
        this._renderDay(date, node, dayEvents);
      },

      _renderDay: function(date, node, events) {
        if (events.length > 0) {
          node.setStyle('background', 'gray');
        }
      },

      _sortEvents: function(events) {
        events.sort(function(a, b) {
          var aTime = getEventTime(a);
          var bTime = getEventTime(b);
          return bTime - aTime;
        });
      },

      /**
       * @return {Boolean}
       */
      _isMultidayEvent: function (eventItem) {
        if (eventItem.recordType !== RecordType.EVENT) {
          return false;
        }

        var structuredContent = eventItem.structuredContent;
        return moment(structuredContent.startDate).diff(
          structuredContent.endDate,
          'days'
        );
      },

      /**
       * @param {Object} event any item post
       * @param {String} String moment-like
       * @return {Boolean}
       */
      _isEventPartOfDay: function(event, displayDay) {
        if (event.recordType !== RecordType.EVENT) {
          return moment(event.addedOn).isSame(displayDay, 'day');
        }

        var structuredContent = event.structuredContent;
        // Check if the displayDay is inclusively in between two days, to the
        // day level (ignoring time).
        return moment(displayDay).isBetween(
          structuredContent.startDate,
          structuredContent.endDate,
          'day',
          '[]'
        );
      },

      /**
       * Checks if an event/post is on the same day as dayOfMonthDate
       * Events that span multiple days are styled differently
       *
       * @param {object} event
       * @param {*} dayOfMonthDate moment-like
       * @return {Boolean}
       */
      _isSameDay: function (event, dayOfMonthDate) {
        if (event.recordType !== RecordType.EVENT) {
          return true;
        }
        return moment(event.structuredContent.startDate).isSame(
          dayOfMonthDate,
          'day'
        );
      },

      _getEventsOfDay: function(events, dayOfMonth) {
        if (Y.Lang.isArray(events) && events.length > 0) {
          return Y.Array.filter(events, function(event) {
            return this._isEventPartOfDay(event, dayOfMonth);
          }, this);
        }
        return [];
      },

      _getEventDisplayTime: function(event) {
        var time = getEventTime(event);
        return dateFormat(time, 'h:mma').trim();
      }
    },

    { NS: 'calendarPlugin' }
  );

  /**
   * Squarespace Calendar Renderer
   *
   * Extends CalendarBaseRenderer to provide a custom UI shared by Calendar
   * Block and Events Collection.
   *
   * @namespace   Squarespace
   * @class       SquarespaceCalendarRenderer
   * @extends     Squarespace.CalendarBaseRenderer
   */
  var SquarespaceCalendarRenderer = Y.Squarespace.SquarespaceCalendarRenderer = Y.Base.create(
    'squarespaceCalendarRenderer',
    Y.Squarespace.CalendarBaseRenderer,
    [],
    {

      initializer: function(config) {
        this.host.set('headerRenderer', Y.bind(this._renderHeader, this));

        this._loadingSpinner = new Y.Squarespace.Spinner({
          color: 'dark',
          size: 'extra-large',
          render: this.host.get('contentBox')
        });

        this.onceAfterHostEvent('eventsChange', this._renderCalendar);

        this.onceAfterHostEvent('fetchEventsFailure', function () {
          if (this._loadingSpinner) {
            this._loadingSpinner.destroy(true);
          }
          this.host.destroy();
        }, this);

        this._bindUI();
      },

      destructor: function() {
        if (this._loadingSpinner) {
          this._loadingSpinner.destroy(true);
        }
        this._resizeEvent.detach();
      },

      _bindUI: function() {
        this._resizeEvent = Y.on('resize', this._syncUI, Y.config.win, this);
      },

      _syncUI: function() {
        if (this.host.get('contentBox').getDOMNode()) {
          this._fitToContainer();
          this._setupCells();

          if (!this.host.get('contentBox').hasClass(SquarespaceCalendarRenderer.COMPACT_LAYOUT_CLASS)) {
            this._loadImages();
          }

          this._setFlyoutHeights();
        }
      },

      /**
       * Customized customRenderer rules object to mark which edge of calendar grid we're on.
       *
       * @override
       * @method _onEventsChange
       */
      _onEventsChange: function(e) {
        var filterFunction = Y.bind(this._calendarFilterFunction, this);
        var rules = {
          all: { // years
            all: { // month
              all: { // day of month
                '0': 'sunday',
                '1': 'monday',
                '2': 'tuesday',
                '3': 'wednesday',
                '4': 'thursday',
                '5': 'friday',
                '6': 'saturday'
              }
            }
          }
        };

        this.host.set('customRenderer', {
          rules: rules,
          filterFunction: filterFunction
        });

        if (this.get('isRendered')) {
          this._syncUI();
        }
      },

      /**
       * Add class to cells based upon customRenderer rules. This allows us to add the appropriate styling to
       * right-/left-edge cells that may have flyout content that would go offscreen.
       *
       * @override
       * @method _calendarFilterFunction
       */
      _calendarFilterFunction: function(date, node, rules) {
        SquarespaceCalendarRenderer.superclass._calendarFilterFunction.apply(this, arguments);

        if (rules.indexOf('sunday') >= 0) {
          node.addClass('sunday');
        } else if (rules.indexOf('monday') >= 0) {
          node.addClass('monday');
        } else if (rules.indexOf('friday') >= 0) {
          node.addClass('friday');
        } else if (rules.indexOf('saturday') >= 0) {
          node.addClass('saturday');
        }
      },

      _fitToContainer: function() {
        var container = this.host.get('contentBox');
        var containerWidth = container.get('offsetWidth');
        container.toggleClass(SquarespaceCalendarRenderer.SMALL_LAYOUT_CLASS, containerWidth <= 770);
        container.toggleClass(SquarespaceCalendarRenderer.COMPACT_LAYOUT_CLASS, containerWidth <= 600);
        container.toggleClass(SquarespaceCalendarRenderer.TINY_LAYOUT_CLASS, containerWidth <= 300);
      },

      _setupHeader: function() {
        // Use H1 for header
        var container = this.host.get('contentBox');
        container.one('.yui3-calendar-header-label').wrap('<h1></h1>');
      },

      _setupCells: function() {
        var table = this.host.get('contentBox').one('table');
        table.setStyle('width', null); // reset any inline values

        var tableEl = table.getDOMNode();
        var tableMaxWidth = tableEl.offsetWidth;

        // Ensure table width never exceeds its parent
        var parentEl = tableEl.parentElement;
        if (parentEl) {
          var parentWidth = parentEl.offsetWidth;
          tableMaxWidth = Math.min(tableMaxWidth, parentWidth);
        }

        var DAYS_PER_WEEK = 7;
        var CELL_SPACERS = DAYS_PER_WEEK + 1;

        this.cellSpacing = parseInt(table.getComputedStyle('borderSpacing'), 10);
        var totalCellSpacing = this.cellSpacing * CELL_SPACERS;
        var cellWidth = parseInt((tableMaxWidth - totalCellSpacing) / DAYS_PER_WEEK, 10);
        var tableCellsWidth = (cellWidth * DAYS_PER_WEEK) + totalCellSpacing;
        var tableWidth = Math.min(tableMaxWidth, tableCellsWidth);
        table.setStyle('width', tableWidth);

        // Cells should be square
        var cellSize = parseInt(tableWidth / DAYS_PER_WEEK, 10) - this.cellSpacing;
        table.all('td').setStyles({
          width: cellSize,
          height: cellSize
        });
      },

      _loadImages: function() {
        this.host.get('contentBox').all('img[data-src]').each(function(img) {
          ImageLoader.load(img, { mode: 'fill', load: true });
        }, this);
      },

      _setFlyoutHeights: function() {
        this.host.get('contentBox').all('.flyoutitemlist--hasmorecontent').each(function(flyout) {

          var flyoutRow = flyout.ancestor('tr');
          var rowHeight = this._getRowHeight(flyoutRow);
          var adjacentRowHeight;

          if (
            flyout.ancestor('tr:nth-child(4)') ||
            flyout.ancestor('tr:nth-child(5)') ||
            flyout.ancestor('tr:nth-child(6)')
          ) {
            adjacentRowHeight = this._getRowHeight(flyoutRow.previous('tr'));
          } else {
            adjacentRowHeight = this._getRowHeight(flyoutRow.next('tr'));
          }

          flyout.setStyle('height', rowHeight + adjacentRowHeight + this.cellSpacing);

        }, this);

        this.host.get('contentBox').all('tr').removeAttribute('data-row-height');

      },

      _getRowHeight: function(row) {
        if (row.getAttribute('data-row-height')) {
          return parseInt(row.getAttribute('data-row-height'), 10);
        }
        var rowHeight = row.get('offsetHeight');
        row.setAttribute('data-row-height', rowHeight);
        return rowHeight;
      },

      _renderCalendar: function() {
        this._setupHeader();
        this._syncUI();

        if (this._loadingSpinner) {
          this._loadingSpinner.destroy(true);
        }

        this.host.get('boundingBox').addClass('loaded');
        this.set('isRendered', true);
      },

      _renderHeader: function(date) {
        return formatDateTime(date, MONTH_YEAR_FORMAT);
      },

      /**
       * Render calendar days with handlebars template since the markup is rather complex.
       *
       * @override
       * @method _renderDay
       */
      _renderDay: function(date, node, events) {
        this.setAttrs({
          date: date,
          node: node,
          events: this._groupEvents(events, date)
        });

        this._highlightToday();
        this._highlightDaysWithEvents();

        var templateInfo = this._getTemplateInfo();
        var compiledTemplate = templateInfo.template(this._getHBTemplateContext());
        node.empty().append(compiledTemplate);
      },

      _highlightToday: function() {
        var today = new Date();
        var todayInWebsiteTimezone = shiftForWebsiteTimezoneDisplay(today);
        if (this.get('date').setHours(0, 0, 0, 0) === todayInWebsiteTimezone.setHours(0, 0, 0, 0)) {
          this.get('node').addClass(SquarespaceCalendarRenderer.TODAY_CLASS);
        }
      },

      _highlightDaysWithEvents: function() {
        if (this.get('events').length > 0) {
          this.get('node').addClass(SquarespaceCalendarRenderer.HAS_EVENT_CLASS);
        }
      },

      _getTemplateInfo: function() {
        return {
          template: Y.Squarespace.UITemplates.getCompiledTemplate(
            SquarespaceCalendarRenderer.HANDLEBARS_TEMPLATE
          )
        };
      },

      _getHBTemplateContext: function() {
        return {
          date: this.get('date'),
          events: this.get('events')
        };
      },

      /**
       * If Events Collection, manage separate arrays of single vs ongoing items (events). And let the
       * HB template know what kind of event it is.
       *
       * Single Day events include (1) single day events, obviously, BUT ALSO (2) the first day of a
       * multiday event. Since we display the start time of the first day of a multiday event, it'll
       * appear in the flow of the day's events.
       *
       * Ongoing events are all subsequent days of a multiday event. They are pulled into a separate
       * array and as will appear FIRST in a list of a day's events; the start time is not displayed.
       *
       * @method _groupEvents
       * @param  {Array}  events  array of the current day we're rendering's events
       * @param  {Date}   date    the current day we're rendering
       */
      _groupEvents: function(events, date) {
        var singleDayEvents = [];
        var ongoingEvents = [];

        Y.Array.each(events, function(eventItem) {
          if (this._isSameDay(eventItem, date)) {
            singleDayEvents.unshift(eventItem);
          } else {
            eventItem.isSubsequentDayOfMultidayEvent = true; // for template
            ongoingEvents.unshift(eventItem);
          }
          if (this._isMultidayEvent(eventItem)) {
            eventItem.isMultiday = true; // for template
          }
        }, this);

        return ongoingEvents.concat(singleDayEvents);
      }

    },
    {
      NS: 'calendarPlugin',
      TODAY_CLASS: 'today',
      HAS_EVENT_CLASS: 'has-event',
      SMALL_LAYOUT_CLASS: 'small-layout',
      COMPACT_LAYOUT_CLASS: 'compact-layout',
      TINY_LAYOUT_CLASS: 'tiny-layout',
      HANDLEBARS_TEMPLATE: 'calendar-day.html'
    }
  );

  Y.Handlebars.registerHelper('day-of-month-format', function(date, options) {
    return formatDateTime(date, options.hash.format || 'LLL');
  });

  Y.Handlebars.registerHelper('date-format', function(date, options) {
    return dateFormat(date, options.hash.format || 'LLL');
  });

  Y.Handlebars.registerHelper('calendar-compact-time-format', function(date) {
    // 10:00am --> 10a
    var meridiem = (dateFormat(date, 'A') === 'PM') ? 'p' : 'a';
    return new Date(date).getMinutes() === 0 ? // if top of the hour, don't show 00
      dateFormat(date, 'h') + meridiem :
      dateFormat(date, 'h:mm') + meridiem;
  });

}, '1.0', {
  requires: [
    'base',
    // order matters, keep these before 'calendar' for overrides
    'intl',
    'squarespace-calendar-base-lang',
    'squarespace-datatype-date-format',
    // needed for YUI's calendar widget
    'calendar',
    'node',
    'squarespace-attr-validators',
    'squarespace-calendar-day-template',
    'squarespace-spinner',
    'squarespace-ui-base',
    'squarespace-ui-templates',
    'squarespace-widgets-alert'
  ]
});
